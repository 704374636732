import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import {ModalController, NavParams, NavController} from '@ionic/angular';
import {ApiService} from '../../services/api.service';
@Component({
  selector: 'app-login-member',
  templateUrl: './login-member.page.html',
  styleUrls: ['./login-member.page.scss'],
})
export class LoginMemberPage implements OnInit {

  constructor(private router: Router,
              private modalCtrl: ModalController,
              private navParams: NavParams,
              private api: ApiService,
              private navCtrl: NavController) { }

  

  user:any={};
  loading:boolean;
  msg:any;

  ngOnInit() {
  }
  doLogin(user)
	{
    this.loading=true;
    this.api.data('auth/login','post',user).subscribe(data=>{
      this.loading=false;
      this.redirect(data);
    },error=>{
      this.loading=false;
      this.showMsg(error);
    })
		
	}
  showMsg(data)
  {
    if(data.error.status=='not_match')
    {
      this.msg='Username dan password tidak cocok';     
      setTimeout(() => {
       this.msg='';
      }, 3000); 
    }
  }

  redirect(data)
  {
    localStorage.setItem('goopitoken',JSON.stringify(data));
    if(data.user.role=='parent')
    {
      this.navCtrl.navigateRoot(['/page/tabs/member/parent']);
    }
    if(data.user.role=='teacher')
    {
      this.navCtrl.navigateRoot(['/page/tabs/member/teacher']);
    }
    this.closeModal();
  }

  closeModal()
  {
    this.modalCtrl.dismiss();
  }




}
