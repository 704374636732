import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule} from '@angular/forms';
import { HttpClientModule }    from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginMemberPage } from './auth/login-member/login-member.page';
import { SchoolListPage } from './auth/school-list/school-list.page';
import { ApiService} from './services/api.service';
import { CalendarService} from './services/calendar.service';

//public page
import { DetailWebsitePage } from './page-public/detail-website/detail-website.page';
import { StudentDetailPage } from './page-public/student-detail/student-detail.page';
import { FormFieldPage } from './page-public/form-field/form-field.page';
import { DetailEksisPage } from './page-public/detail-eksis/detail-eksis.page';
//tab-account
import { InformasiAkunPage } from './tab-account/informasi-akun/informasi-akun.page';
import { ResetPasswordPage } from './tab-account/reset-password/reset-password.page';
import { PrivacyPolicyPage } from './tab-account/privacy-policy/privacy-policy.page';
//tab-member>>teacher
import { BuatProgramPage } from './tab-member/teacher/buat-program//buat-program.page';
//tab-member>>parent
import { NilaiEkskulPage } from './tab-member/parent/nilai-ekskul/nilai-ekskul.page';


@NgModule({
  declarations: [
  		AppComponent,
      LoginMemberPage,
      SchoolListPage,
      DetailWebsitePage,
      StudentDetailPage,
      FormFieldPage,
      DetailEksisPage,
      InformasiAkunPage,
      ResetPasswordPage,
      PrivacyPolicyPage,
      BuatProgramPage,
      NilaiEkskulPage
  		],
  entryComponents: [
      LoginMemberPage,
      SchoolListPage,
      DetailWebsitePage,
      StudentDetailPage,
      FormFieldPage,
      DetailEksisPage,
      InformasiAkunPage,
      ResetPasswordPage,
      PrivacyPolicyPage,
      BuatProgramPage,
      NilaiEkskulPage
  		],
  imports: [
  BrowserModule, 
  FormsModule,  
  IonicModule.forRoot(), 
  AppRoutingModule,
  HttpClientModule],
  providers: [
    ApiService,
    CalendarService,
    StatusBar,
    Camera,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
