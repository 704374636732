import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import{ApiService} from '../../../services/api.service';

@Component({
  selector: 'app-buat-program',
  templateUrl: './buat-program.page.html',
  styleUrls: ['./buat-program.page.scss'],
})
export class BuatProgramPage implements OnInit {

  constructor(private modalCtrl:ModalController, private navCtrl:NavParams, private api:ApiService) { }

  tahun:any;
  semester:any;
  tingkat:any;
  kategori:any;
  mapelID:any;
  judul:any;

  ngOnInit() {
  }

  jenis:any=[
    {
      id:'UH',
      label:'Ulangan Harian'
    },
    {
      id:'T',
      label:'Tugas'
    },
    {
      id:'UTS',
      label:'Ulangan Tengah Semester'
    },
    {
      id:'UAS',
      label:'Ulangan Akhir Semester'
    }
  ];

  loading:boolean;
  program:any={};
  simpan(dat)
  {
      this.loading=true;
      if(dat.id==undefined)
      {
        dat.tahun=this.tahun;
        dat.tingkat=this.tingkat;
        dat.mapelID=this.mapelID;
        dat.kategori=this.kategori;
        if(this.semester=='GASAL') dat.semester=1; else dat.semester=2;
        this.api.data('akademik/program','post',dat).subscribe(data=>{          
          this.loading=false;
          this.modalCtrl.dismiss(true);
        },error=>{
          this.loading=false;
        })
      }else{
        this.api.data('akademik/program/'+dat.id,'put',dat).subscribe(data=>{          
          this.loading=false;
          this.modalCtrl.dismiss(true);
        },error=>{
          this.loading=false;
        })
      }
      
  }

  closeModal()
  {
    this.modalCtrl.dismiss(false);
  }

}
