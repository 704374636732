import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';

@Component({
  selector: 'app-detail-website',
  templateUrl: './detail-website.page.html',
  styleUrls: ['./detail-website.page.scss'],
})
export class DetailWebsitePage implements OnInit {

  constructor(private modalCtrl:ModalController) { }

  ngOnInit() {
  }

  closeModal()
  {
    this.modalCtrl.dismiss();
  }

}
