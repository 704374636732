import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import{ApiService} from '../../../services/api.service';

@Component({
  selector: 'app-nilai-ekskul',
  templateUrl: './nilai-ekskul.page.html',
  styleUrls: ['./nilai-ekskul.page.scss'],
})
export class NilaiEkskulPage implements OnInit {
  
  ID:any;
  studentData:any={};
  constructor(
  private modalCtrl:ModalController, 
  private navCtrl:NavParams,
  private api:ApiService) {
    this.ID=this.navCtrl.get('id');
    this.studentData=this.navCtrl.get('studentData');
    this.getData();
  }

  ngOnInit() {
  }

  dataNilai:any={};
  getData()
  {
    this.api.data('api/pesertaekstra?ekstraID='+this.ID+'&tahun=7&semester=GENAP','get','').subscribe(data=>{     
     
      
    })
  }


  closeModal()
  {
    this.modalCtrl.dismiss();
  }

}
