import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import {ApiService} from '../../services/api.service';
@Component({
  selector: 'app-school-list',
  templateUrl: './school-list.page.html',
  styleUrls: ['./school-list.page.scss'],
})
export class SchoolListPage implements OnInit {

  constructor(public modalCtrl:ModalController, private api:ApiService) { }

  ngOnInit() 
  {
      this.getSch();
  }
  dataSch:any=[];
  getSch()
  {
      this.api.data('sekolah','get','').subscribe(data=>{
          this.dataSch=data;
      })
  }
  closeModal()
  {
    this.modalCtrl.dismiss(false);
  }

}
