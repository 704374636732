import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import{ApiService} from '../../services/api.service';

@Component({
  selector: 'app-informasi-akun',
  templateUrl: './informasi-akun.page.html',
  styleUrls: ['./informasi-akun.page.scss'],
})
export class InformasiAkunPage implements OnInit {

  constructor(private modalCtrl:ModalController, private navCtrl:NavParams, private api:ApiService) { }

  ngOnInit() {
    this.getUserData();
  }

  userData:any={};
  getUserData()
  {
    this.api.data('user/me','get','').subscribe(data=>{
        this.userData=data;
    })
  }
  
  closeModal()
  {
    this.modalCtrl.dismiss();
  }

}
