import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
      path: 'login-sch',
      loadChildren: './auth/login-sch/login-sch.module#LoginSchPageModule'
  },
  {
      path: 'page',
      loadChildren: './tabs/tabs.module#TabsPageModule'
  },
  {
      path: '',
      redirectTo: 'login-sch',
      pathMatch: 'full',
  },
  { path: 'ekskul-setting', loadChildren: './tab-member/teacher/ekskul-setting/ekskul-setting.module#EkskulSettingPageModule' }


  ];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
