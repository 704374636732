import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-detail-eksis',
  templateUrl: './detail-eksis.page.html',
  styleUrls: ['./detail-eksis.page.scss'],
})
export class DetailEksisPage implements OnInit {

  constructor(private modalCtrl:ModalController, private navCtrl:NavController) { }

  ngOnInit() {
  }

  posting:any={};

  closeModal()
  {
    this.modalCtrl.dismiss();
  }

}
