import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router} from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class ApiService {
	serverUrl:any='http://api.sunhouse.co.id/sch/index.php/';
	//serverUrl:any='http://localhost/schv3.sunhouse.xyz/index.php/';
	constructor(private http:HttpClient, private router:Router) { }

	httpOptions:any;
    key_app:any;
    exp:any;
    user:any={};
    getToken()
    {
        var tokenKey=localStorage.getItem('goopitoken');
		if(tokenKey!=null)
		{
			var tkn=JSON.parse(tokenKey);
			var user=tkn.user;
			var jwt=tkn.jwt;
			this.httpOptions={
			  headers: new HttpHeaders({
			 'Content-Type':  'application/json',
			 'Authorization': 'Bearer '+jwt
			  })
			}
            this.key_app=tkn.user.keyApp;
            this.exp=tkn.exp;
            this.user=tkn.user;
		}
    }
    isLoggedIn()
    {
    	this.getToken();
    	var today=new Date().getTime();
    	var expired=new Date(this.exp*1000).getTime();    	
    	if(this.key_app==null && today>expired) {
    		 this.router.navigate(['/page/tabs/tab2']);
    	}else 
    	{
    		return this.user;
    	}
    }
	data(url,method,data)
	{
		this.getToken();
		if(method=='get')
		{
			return this.http.get(this.serverUrl+url,this.httpOptions);
		}
		if(method=='post')
		{
			return this.http.post(this.serverUrl+url,data,this.httpOptions);
		}
		if(method=='put')
		{
			return this.http.put(this.serverUrl+url,data,this.httpOptions);
		}
		if(method=='delete')
		{
			return this.http.delete(this.serverUrl+url,this.httpOptions);
		}
	}
}
